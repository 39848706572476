import styled from "styled-components"
import Container from "@material-ui/core/Container"

export const Wrapper = styled(Container)`
  margin-bottom: 24px;
  padding: 3rem 3rem;

  a { color: #00b3f3; }

  h2, h3 {
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }

  p, ul {
    margin-bottom: 1rem;

    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }

  &.larger {
    font-size: 1.3em;
  }

  &.extra-padding {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  &.blue-gradient {
    color: #fff;
    margin-bottom: 0;
    background: linear-gradient(265deg, #0d214c, #0096c6);

    h3 {
      color: #fff;
      line-height: 1.3em;
      &:last-child { margin-bottom: 0; }
    }

    &.flip {
      background: linear-gradient(90deg, #0d214c, #0096c6);
    }
  }

  &.margin-bottom {
    margin-bottom: 2rem;
  }
`
